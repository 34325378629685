import { Token, VStack } from '@revolut/ui-kit'
import { ReviewScorecardInterface } from '@src/interfaces/performance'
import { CultureValuesCard } from '@src/pages/Forms/EmployeePerformanceLayout/Cards/CultureValuesCard'
import { ManagerSkillsCard } from '@src/pages/Forms/EmployeePerformanceLayout/Cards/ManagerSkillsCard'
import { SkillsCard } from '@src/pages/Forms/EmployeePerformanceLayout/Cards/SkillsCard'
import {
  RecommendedGradesContext,
  getRecommendationAndType,
} from '@src/pages/Forms/EmployeePerformanceLayout/ScorecardContent'
import { AdditionalQuestions } from '@src/pages/Forms/EmployeePerformanceLayout/Sections/AdditionalQuestions'
import { BarRaiser } from '@src/pages/Forms/EmployeePerformanceLayout/Sections/BarRaiser'
import { Feedback } from '@src/pages/Forms/EmployeePerformanceLayout/Sections/Feedback'
import { getRecommendedGrade } from '@src/pages/Forms/EmployeePerformanceLayout/utils'
import React from 'react'
import { DeliverablesCard } from './DeliverablesCard'
import { PerformanceHeader } from '@src/pages/Forms/EmployeePerformanceLayout/components/PerformanceHeader'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { useGetReviewGradesMap } from '@src/utils/grades'
import get from 'lodash/get'
import { Pencil } from '@revolut/icons'
import { navigateTo } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'

export const ScorecardPreview = () => {
  const { values } = useLapeContext<ReviewScorecardInterface>()
  const grades = getRecommendedGrade(values)
  const gradesMap = useGetReviewGradesMap()
  const recommendationSection = getRecommendationAndType(values)

  const commonProps = {
    gradesMap,
  }

  const getValueTitleButton = (field: string) => {
    const cultureValueCardId = get(values, field)?.value_id
    if (!cultureValueCardId) {
      return null
    }
    return (
      <Pencil
        size={20}
        color={Token.color.greyTone20}
        cursor="pointer"
        onClick={() => {
          navigateTo(
            pathToUrl(ROUTES.ONBOARDING_CHECKLIST_V2.REVIEWS.VALUES_FORM, {
              id: cultureValueCardId,
            }),
          )
        }}
      />
    )
  }

  return (
    <RecommendedGradesContext.Provider value={grades}>
      <VStack space="s-16" use="div" width="100%">
        <PerformanceHeader
          forceEmployeeId={values.reviewed_employee.id}
          variant="onboarding"
        />
        <DeliverablesCard
          {...commonProps}
          setMissingDeliverablesJustification={() => {}}
          showBeforeSubmitCheckError={false}
        />
        <SkillsCard {...commonProps} skillsMissingJustification={[]} />
        <ManagerSkillsCard {...commonProps} />
        <CultureValuesCard
          {...commonProps}
          titleButton={field => getValueTitleButton(field)}
        />
        {recommendationSection && (
          <BarRaiser
            isViewMode={false}
            questions={recommendationSection.recommendation.keeper_test_section.questions}
            type={recommendationSection.type}
            field={recommendationSection.field}
          />
        )}
        <AdditionalQuestions data={values.review_data} isViewMode={false} />
        <Feedback
          recommendationData={recommendationSection?.recommendation}
          type={recommendationSection?.type}
          showMissingJustificationError={false}
          gradesMap={gradesMap}
        />
      </VStack>
    </RecommendedGradesContext.Provider>
  )
}
