import uniq from 'lodash/uniq'

import {
  BulkUploadConfigurableField,
  BulkUploadConfigurableFieldCategory,
  ConfigurableBulkUploadEntity,
  ImportSessionInterface,
} from '@src/interfaces/bulkDataImport'
import { useGetSectionCustomFields } from '@src/api/customFields'
import {
  CustomFieldsForSectionInterface,
  SectionOptions,
} from '@src/interfaces/customFields'
import {
  getUploadSessionTable,
  useGetConfigurableBulkUploadFields,
} from '@src/api/bulkDataImport'
import { EMPLOYEE_PROFILE_SUB_SECTIONS } from '@src/interfaces/employees'
import { useEffect } from 'react'
import isObject from 'lodash/isObject'
import { TEMPLATE_TYPE_QUERY_KEY } from '@src/features/BulkDataImportV2/constants'
import { supportedEmployeeCustomFieldsTypes } from '@src/features/EditableEmployeesTable/common/constants'
import { useQuery } from '@src/utils/queryParamsHooks'
import {
  DYNAMIC_COLUMNS_QUERY_KEY,
  CUSTOM_FIELDS_QUERY_KEY,
} from '@src/features/GenericEditableTable/constants'
import { BulkDataImportSessionProps } from './types'

export const getAddFieldButtonProps = (
  entityName: string,
  field: BulkUploadConfigurableField,
  addedFields: BulkUploadConfigurableField[],
) => {
  if (entityName === 'contract' && field.column_name === 'subseniority') {
    return {
      disabled: !addedFields.some(f => f.column_name === 'seniority'),
    }
  }
  return { disabled: false }
}

export const getAddedFieldsAfterDeletion = (
  entityName: string,
  field: BulkUploadConfigurableField,
  addedFields: BulkUploadConfigurableField[],
) => {
  if (entityName === 'contract' && field.column_name === 'seniority') {
    return addedFields.filter(
      f => f.column_name !== 'seniority' && f.column_name !== 'subseniority',
    )
  }
  return addedFields.filter(f => f.column_name !== field.column_name)
}

type CustomFieldSubSectionKey = keyof typeof EMPLOYEE_PROFILE_SUB_SECTIONS
type CustomFieldSubSectionId =
  typeof EMPLOYEE_PROFILE_SUB_SECTIONS[CustomFieldSubSectionKey]

const employeeCustomFieldSubsectionIdToConfigurableFieldCategory: Record<
  CustomFieldSubSectionId,
  BulkUploadConfigurableFieldCategory
> = {
  [EMPLOYEE_PROFILE_SUB_SECTIONS.ID]: 'General',
  [EMPLOYEE_PROFILE_SUB_SECTIONS.BASIC_INFO]: 'General',
  [EMPLOYEE_PROFILE_SUB_SECTIONS.CONTACT_INFO]: 'Contacts',
  [EMPLOYEE_PROFILE_SUB_SECTIONS.CORRESPONDENCE_ADDRESS]: 'Contacts',
  [EMPLOYEE_PROFILE_SUB_SECTIONS.BIO]: 'About',
  [EMPLOYEE_PROFILE_SUB_SECTIONS.ABOUT_YOU]: 'About',
  [EMPLOYEE_PROFILE_SUB_SECTIONS.NOTES]: 'About',
  [EMPLOYEE_PROFILE_SUB_SECTIONS.WORK]: 'Position',
  [EMPLOYEE_PROFILE_SUB_SECTIONS.BANK_DETAILS]: 'Bank details',
  [EMPLOYEE_PROFILE_SUB_SECTIONS.CONTRACTS]: 'Position',
  [EMPLOYEE_PROFILE_SUB_SECTIONS.IDENTIFICATION_DETAILS]: 'Identification details',
}

export const useGetBulkUploadFields = (entity?: ConfigurableBulkUploadEntity) => {
  const { data: configurableFields = [], ...fieldsCtx } =
    useGetConfigurableBulkUploadFields(entity)

  const customFieldsSection =
    entity === 'employees' ? SectionOptions.EmployeeProfile : undefined
  const { data: customFieldsData, ...customFieldsCtx } = useGetSectionCustomFields(
    customFieldsSection,
    [],
  )

  const customFieldToConfigurable = (
    cf: CustomFieldsForSectionInterface,
  ): BulkUploadConfigurableField => ({
    name: cf.name,
    category:
      employeeCustomFieldSubsectionIdToConfigurableFieldCategory[
        cf.sub_section?.id as CustomFieldSubSectionId
      ] || 'Custom fields',
    column_name: cf.field_name,
    is_required: false,
    custom_field_id: cf.uid,
  })

  const configurableCustomFields =
    customFieldsData?.results
      .filter(cf => supportedEmployeeCustomFieldsTypes.includes(cf.input_type.id))
      .map(customFieldToConfigurable) || []

  return {
    ...fieldsCtx,
    data: [...configurableFields, ...configurableCustomFields],
    isLoading: fieldsCtx.isLoading || customFieldsCtx.isLoading,
  }
}

export const getTemplateDownloadConfigurableFieldsParams = (
  mandatoryColumns: string[],
  configurableFields: BulkUploadConfigurableField[],
) => {
  const optionalColumns = configurableFields
    .filter(f => !f.custom_field_id)
    .map(f => f.column_name)
  const customFieldsColumns = configurableFields
    .map(f => f.custom_field_id)
    .filter(Boolean)
  const allColumns = uniq([...mandatoryColumns, ...optionalColumns])

  return configurableFields.length
    ? {
        version: '2',
        ...(allColumns.length ? { extra_columns: allColumns.join(',') } : null),
        ...(customFieldsColumns.length
          ? { extra_custom_fields: customFieldsColumns.join(',') }
          : null),
      }
    : null
}

export const getUploadParamsByConfigurableFields = (
  configurableFields: BulkUploadConfigurableField[],
) => {
  return {
    custom_fields: configurableFields
      .map(f => (f.custom_field_id ? { id: f.custom_field_id, name: f.name } : null))
      .filter(Boolean),
  }
}

export const useDynamicTemplateQueryParams = <T>({
  apiEndpoint,
  apiVersion,
  sessionData,
}: { sessionData: ImportSessionInterface | undefined } & Pick<
  BulkDataImportSessionProps<T>,
  'apiEndpoint' | 'apiVersion'
>) => {
  const { query, changeQueryParam } = useQuery()

  const isDynamicTemplate = query[TEMPLATE_TYPE_QUERY_KEY] === 'dynamic'

  const sessionCustomFields = sessionData?.custom_fields || []
  const customFieldsQueryParam = sessionCustomFields.map(cf => cf.id).join(',')

  useEffect(() => {
    if (!isDynamicTemplate) {
      return
    }
    if (
      sessionData?.state.id === 'ready_for_review' &&
      !query[DYNAMIC_COLUMNS_QUERY_KEY]
    ) {
      const prefetchTableData = getUploadSessionTable<T>(
        apiEndpoint,
        sessionData.id,
        apiVersion,
      )
      prefetchTableData({}).then(res => {
        const firstItemData = res.data.results[0]?.data
        if (isObject(firstItemData)) {
          changeQueryParam(
            DYNAMIC_COLUMNS_QUERY_KEY,
            Object.keys(firstItemData).join(','),
          )
        }
      })
    }
    if (customFieldsQueryParam && !query[CUSTOM_FIELDS_QUERY_KEY]) {
      changeQueryParam(CUSTOM_FIELDS_QUERY_KEY, customFieldsQueryParam)
    }
  }, [query.template, query.data, sessionData?.state.id])
}
