import React from 'react'
import groupBy from 'lodash/groupBy'
import {
  Item,
  VStack,
  Text,
  Token,
  TextButton,
  Subheader,
  Cell,
  HStack,
} from '@revolut/ui-kit'

import Icon from '@src/components/Icon/Icon'
import SideBar from '@components/SideBar/SideBar'
import { BulkUploadConfigurableField } from '@src/interfaces/bulkDataImport'
import { getAddFieldButtonProps, useGetBulkUploadFields } from './helpers'

type Props = {
  isOpen: boolean
  onClose: () => void
  entityName: string
  onAddField: (field: BulkUploadConfigurableField) => void
  onRemoveField: (field: BulkUploadConfigurableField) => void
  addedFields: BulkUploadConfigurableField[]
  mandatoryColumns: string[]
}
export const AddFieldsSidebar = ({
  isOpen,
  onClose,
  entityName,
  onAddField,
  onRemoveField,
  addedFields,
  mandatoryColumns,
}: Props) => {
  const { data: configurableFieldsData = [] } = useGetBulkUploadFields(
    entityName === 'contract'
      ? 'contracts'
      : entityName === 'goals'
      ? 'goals'
      : 'employees',
  )

  const groupedFieldsData: Record<string, BulkUploadConfigurableField[]> = groupBy(
    configurableFieldsData,
    field => field.category,
  )
  return (
    <SideBar title="Add fields" isOpen={isOpen} onClose={onClose}>
      <VStack space="s-16">
        {Object.entries(groupedFieldsData).map(([group, fields]) => (
          <React.Fragment key={group}>
            <Subheader variant="nested">
              <Subheader.Title>{group}</Subheader.Title>
            </Subheader>
            <Cell p={0} mt="-s-16">
              <VStack width="100%">
                {fields.map(field => (
                  <Item key={field.name}>
                    <Item.Content>
                      <HStack space="s-8" align="center">
                        <Text>{field.name}</Text>
                        {field.custom_field_id && <Icon type="CF" />}
                      </HStack>
                    </Item.Content>
                    <Item.Side>
                      {field.is_required ||
                      mandatoryColumns.includes(field.column_name) ? (
                        <Text
                          color={Token.color.greyTone50}
                          aria-label={`${field.name} preset`}
                        >
                          Preset
                        </Text>
                      ) : addedFields.find(f => f.name === field.name) ? (
                        <TextButton
                          onClick={() => onRemoveField(field)}
                          aria-label={`Remove ${field.name}`}
                        >
                          Remove
                        </TextButton>
                      ) : (
                        <TextButton
                          onClick={() => onAddField(field)}
                          {...getAddFieldButtonProps(entityName, field, addedFields)}
                          aria-label={`Add ${field.name}`}
                        >
                          Add
                        </TextButton>
                      )}
                    </Item.Side>
                  </Item>
                ))}
              </VStack>
            </Cell>
          </React.Fragment>
        ))}
      </VStack>
    </SideBar>
  )
}
